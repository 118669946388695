body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.strikeout {
  font-size: 1.7em;
  line-height: 1em;
  position: relative;
}
.strikeout::after {
  border-bottom: 0.125em solid black;
  content: "";
  left: 0;
  margin-top: calc(0.125em / 2 * -1);
  position: absolute;
  right: 0;
  top: 50%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.dataTables_filter {
  float: right;
}
.custom-select {
  margin-left: 0px !important;
}
table thead .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

table thead .dropup .caret,
table thead .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}
.Collapsible__trigger is-closed {
  border-bottom: 1px solid black !important;
}
.Collapsible__trigger {
  /*background: darkgray;*/
  color: #495057;
}
.Collapsible {
  text-align: left;
  padding-bottom: 20px;
}
.Collapsible__contentOuter {
  background: white;
  border-bottom: 0px solid white;
  border-top: 1px solid #ced4da;
  padding-top: 20px;
}
.playerModal {
  max-width: 85vw !important;
  max-height: 85vh;
  width: 85vw;
  height: 85vh;
}
.smallModal {
  max-width: 85vw !important;
  max-height: 85vh;
  width: 50vw;
}
div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: 1px solid #eee;
  padding: 0 4px;
}

div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  cursor: move;
  border-radius: 2px;
}
div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
  z-index: 999;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}
div.ReactTags__selected span.ReactTags__tag {
  background: #b7cfe0;
}
.react-search-field {
  width: 50% !important;
}
.tablePadding {
  padding-top: 15px;
  padding-right: 25px;
  padding-left: 25px;
}

@media print {
  .MuiPaper-root {
    box-shadow: 0px 0px 0px 0px !important;
  }
  @page {
    size: auto !important;
  }

  .noPrint
  {
      display: none !important;
  }

  .table td,
  th {
    padding: 5px !important;
    font-size: 11px !important;
  }
  .poster td {
    font-size: 3em !important;
  }
  .poster-1 td {
    font-size: 1rem !important;
  }
  .selection-cell,
  .selection-cell-header {
    display: none;
  }
  .table-bordered td,
  .table-bordered th {
    border: 1px solid black !important;
  }

  .strikeout {
    font-size: 1em !important;
  }

  .poster td.table-text {
    font-size: 1.7em !important;
  }

  /* styles go here */
}

/*
div.ReactTags__selected span.ReactTags__tag{
  background: #b7cfe0;

}*/
